html {
    font-size: 16px;
}

body {
    color: #000;
}

.lenteCinza td {
    background-color: rgba(0, 0, 0, 0.04);
}

[role="table"] [role="rowgroup"] [role="row"] div {
    padding-right: 0px;
}

hr {
    background-color: transparent;
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .4), transparent);
}

.text-justify {
    text-align: justify;
}

.form-control {
    background-color: #f7faff;
}

.fixoRodape {
    z-index: 100;
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 10px;
    box-shadow: 0 0 10px #ececec;
    background-color: #fff;
    position: fixed;
    left: 0px;
    bottom: 0px;
    right: 0px;
}

.campoHora {
    max-width: 200px;
}

.imagemFundoEvento {
    background-position-y: 50%;
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: fixed;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .6;
}

.required {
    border: 1px solid black;
    border-radius: 5px;
}

@media (min-width: 1200px) {
    .conjuntoBoxBriefing {
        top: 250px;
    }
}

.conjuntoBoxBriefing {
    position: relative;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
    gap: 20px;
    justify-content: center;
}

.boxBriefing {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: #5a8dee;
    margin-bottom: 20px;
    width: 250px;
    height: 200px;
    padding: 20px;
    border-radius: 20px;
}

.boxBriefing-text {
    text-align: center;
    color: #fff;
    font-size: 20px;
}

.boxBriefing-button {
    display: flex;
    justify-content: center;
}

.botaoNavLink {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.botaoDropdownItem {
    cursor: pointer;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration-line: none;
}

.form-group {
    margin-top: 5px;
}

/*
.form-floating input {
    transition: all 0.5s ease-out;
}
*/

.sigCanvas {
    box-shadow: rgba(25, 42, 70, 0.13) -8px 12px 18px 0px;
    border: 1px solid black;
}

.form-group label {
    font-weight: bold;
    opacity: 1 !important;
    color: #266391;
}

.ck-editor__editable {
    min-height: 500px;
}

.swal2-content {
    z-index: 2 !important;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

a {
    color: #194f78;
}

.maisMargem {
    padding-top: 1rem;
    padding-left: 1rem;
}

.navbar {
    background-color: #fff;
    height: 50px !important;
    padding: 0.5rem;
    box-shadow: 0 0 10px #ececec;
    z-index: 102;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5em !important;
}

.card-title {
    margin-left: 10px;
}

.greyback {
    background-color: rgba(0, 0, 0, 0.1);
}

#main {
    margin-left: 75px;
}

#main .main-content {
    padding: 0.75rem !important;
}

.main-content .nav {
    padding-top: 0;
    padding-bottom: 0.5rem;
}

.right {
    text-align: right;
}

.page-title {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
}

.modal .modal-header .close {
    padding: 7px 10px;
    border-radius: 50%;
    background: none;
    border: none;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.card-header {
    padding: 0;
}

.pricing h1 {
    margin-bottom: 0;
}

.form-control {
    /*    background-color:#f7faff; */
}

.card-body>* {
    overflow: unset !important;
    overflow-y: unset !important;
}

.nav .nav-item {
    margin-left: 10px;
}

.btn-sm {
    padding: .3rem .6rem !important;
    font-size: 0.7rem;
    border-radius: .267rem;
}

.sidebar-wrapper {
    z-index: 101;
    position: relative;
    float: left;
    width: 75px !important;
}

.sidebar-wrapper .menu {
    margin-top: 0;
}

.sidebar-wrapper .menu .sidebar-link {
    padding: .7rem 0rem !important;
    display: block;
    text-align: center;
}

.sidebar-wrapper .menu .sidebar-link>span {
    margin-left: 0;
    display: block;
}

.sidebar-wrapper .menu .sidebar-item {
    margin-top: 0;
}

.card {
    margin-bottom: 1rem;
}

@media print {
    #main {
        margin-left: 0px !important;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }
}